<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-card>
      <SalesOrderComponents />
    </b-card>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import SalesOrderComponents from '@/components/salesorder/SalesOrderComponents.vue'

export default {
  name: 'ManagerSalesOrder',

  components: {
    SalesOrderComponents
  },

  middleware: ['auth', 'manager'],

  metaInfo () {
    return {
      title: 'Sales Order'
    }
  },

  mounted () {
    core.index()
  }
}
</script>
